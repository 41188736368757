@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: 'Pretendard-Regular', Pretendard-Regular;
  scroll-behavior: smooth;
}

.footer {
  background: #26272D;
  background-size: cover;
}



