@media (max-width: 400px) {
    .banpoom {
        margin-left: 14px;
    }

    .choolgan {
        margin-left: 14px;
    }

    .defaultwarehouse{
        margin-left: 14px;
    }


}
